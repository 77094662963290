import React, { useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import FlyerImg from "../Productsimages/FlyerIcon.png";
import CouponImg from "../Productsimages/CouponIcon.png";
import MarketingIcon from "../Productsimages/marketingIcon.png";
import "./Servicelists.css";
function Servicelists({
  scrollToDTransfer,
  scrollToCoupon,
  scrollToFlyer,
  scrollToOneDay,
  scrollTopushNotifications,
  scrollToPushRadius,
  scrolltoSTorage,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === "/services") {
      navigate("/services/flyer");
    }
  }, [location, navigate]);

  return (
    <>
      <div>
        <div className="banner-container">
          <h3>PRODUCTS</h3>
        </div>
        <div style={styles.productsContainer}>
          <Link to="/services/flyer" style={styles.linkstyle}>
            <div style={styles.productdivs} className="products-div">
              <img
                src={FlyerImg}
                style={styles.productsImages}
                alt="flyer-services"
              />
              <p style={styles.productNames}>Flyer</p>
              <button style={styles.btnStyle} onClick={scrollToFlyer}>
                See More
              </button>
            </div>
          </Link>

          <Link to="/services/coupon" style={styles.linkstyle}>
            <div style={styles.productdivs} className="products-div">
              <img src={CouponImg} style={styles.productsImages} alt="coupon" />
              <p style={styles.productNames}>Coupon</p>
              <button style={styles.btnStyle} onClick={scrollToCoupon}>
                See More
              </button>
            </div>
          </Link>

          <Link to="/services/storage" style={styles.linkstyle}>
            <div style={styles.productdivs} className="products-div">
              <img
                src={`https://cdn.pixabay.com/photo/2021/10/11/00/59/upload-6699084_1280.png`}
                style={styles.productsImages}
                alt="storage-services"
              />
              <p style={styles.productNames}>Storage</p>
              <button style={styles.btnStyle} onClick={scrolltoSTorage}>
                See More
              </button>
            </div>
          </Link>

          <Link to="/services/pushradius" style={styles.linkstyle}>
            <div style={styles.productdivs} className="products-div">
              <img
                src={`https://cdn.pixabay.com/photo/2013/07/13/11/42/map-158494_960_720.png`}
                alt="push-radius services"
                style={styles.productsImages}
              />
              <p style={styles.productNames}>Push Radius</p>
              <button style={styles.btnStyle} onClick={scrollToPushRadius}>
                See More
              </button>
            </div>
          </Link>
          <Link to="/services/pushnotifications" style={styles.linkstyle}>
            <div style={styles.productdivs} className="products-div">
              <img
                src={`https://cdn.pixabay.com/photo/2015/12/16/17/41/bell-1096280_960_720.png`}
                style={styles.productsImages}
                alt="push-notifications-services"
              />
              <p style={styles.productNames}>Push Notifications</p>
              <button
                style={styles.btnStyle}
                onClick={scrollTopushNotifications}
              >
                See More
              </button>
            </div>
          </Link>

          <Link to="/services/dtransfer" style={styles.linkstyle}>
            <div style={styles.productdivs} className="products-div">
              <img
                src={`https://cdn.pixabay.com/photo/2020/03/17/17/36/database-4941301_1280.png`}
                style={styles.productsImages}
                alt="dtransfer"
              />
              <p style={styles.productNames}>dTransfer</p>
              <button style={styles.btnStyle} onClick={scrollToDTransfer}>
                See More
              </button>
            </div>
          </Link>

          <Link to="/services/onedaycampaign" style={styles.linkstyle}>
            <div style={styles.productdivs} className="products-div">
              <img
                src={MarketingIcon}
                style={styles.productsImages}
                alt="1 daycampaign services"
              />
              <p style={styles.productNames}>1 Day Campaign</p>
              <button style={styles.btnStyle} onClick={scrollToOneDay}>
                See More
              </button>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Servicelists;
const styles = {
  productsContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "15px",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "2rem",
    paddingTop: "20px",
  },
  productdivs: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid grey",
    borderRadius: "5px",
    width: "15vw",
    padding: "10px",
    marginBottom: "10px",
  },
  productsImages: {
    maxHeight: "90px",
    maxWidth: "90px",
    height: "70px",
    width: "70px",
  },
  productNames: {
    fontSize: "0.88em",
    fontFamily: "montserrat",
    fontWeight: "500",
    color: "#ffffffde",
    textAlign: "center",
  },
  btnStyle: {
    background: "gray",
    padding: "4px",
    borderRadius: "16px",
    paddingLeft: "10px",
    paddingRight: "10px",
    cursor: "pointer",
    fontWeight: 500,
    border: "1px solid black",
    fontFamily: "montserrat ",
  },
  linkstyle: {
    textDecoration: "none",
  },
};
